import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageSection from "../components/pageSection"
import SubHeader from "../components/subHeader"
import Input from "../components/input"
import Button from "../components/button"
import Textarea from "../components/textarea"

const WorkPage = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [statusMessage, setStatusMessage] = useState("")

  useEffect(() => {
    if (statusMessage.length) {
      setTimeout(() => {
        setStatusMessage("")
      }, 10000)
    }
  }, [statusMessage])

  const onInputChange = setter => event => {
    setter(event.target.value)
  }

  const onFormSubmit = async e => {
    e.preventDefault()

    if (
      name.length &&
      email.match(emailRegexExpression) &&
      message.length &&
      message.length < 4000
    ) {
      try {
        await sendMessage()
        setStatusMessage("Yeeey! Message succesfully sent 🚀")
        clearForm()
      } catch (e) {
        setStatusMessage("Something went wrong. Please try again 🙏🏼")
        console.log(e)
      }
    } else {
      setStatusMessage("Please fill all inputs 🙏🏼")
    }
  }

  const sendMessage = () => {
    const encode = data => {
      return Object.keys(data)
        .map(
          key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&")
    }

    return fetch("/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, message }),
    })
  }

  const clearForm = () => {
    setName("")
    setEmail("")
    setMessage("")
  }

  return (
    <Layout>
      <SEO title={"Contact"} />
      <PageSection first>
        <SubHeader emoji={"🙋🏼‍♀️"}>Get in touch</SubHeader>
        <form
          onSubmit={onFormSubmit}
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <Input
            name={"name"}
            value={name}
            label={"Name"}
            onChange={onInputChange(setName)}
          />
          <Input
            name={"email"}
            value={email}
            label={"E-mail"}
            type={"email"}
            onChange={onInputChange(setEmail)}
          />
          <Textarea
            name={"message"}
            value={message}
            label={`How can I help? (${4000 - message.length} character${
              4000 - message.length === 1 ? "" : "s"
            } remaining)`}
            maxlength="4000"
            onChange={onInputChange(setMessage)}
          />
          <Button text={"Submit"} type={"submit"} />

          {statusMessage && (
            <span
              style={{
                color: "var(--textColor)",
              }}
            >
              {statusMessage}
            </span>
          )}
        </form>
      </PageSection>
    </Layout>
  )
}

export default WorkPage

const emailRegexExpression = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
