import React, { useContext } from "react"

import textAreaStyle from "../styles/textArea.module.scss"
import ThemeContext from "./context/themeContext"

const Textarea = props => {
  const { isDarkThemeOn } = useContext(ThemeContext)

  return (
    <div className={textAreaStyle.container}>
      {props.label && (
        <span className={textAreaStyle.label}>{props.label}</span>
      )}
      <textarea
        {...props}
        className={`${textAreaStyle.textarea} ${
          isDarkThemeOn ? textAreaStyle.dark : ""
        }`}
      />
    </div>
  )
}

export default Textarea
