import React, { useContext } from "react"
import inputStyle from "../styles/input.module.scss"
import ThemeContext from "./context/themeContext"

const Input = props => {
  const { isDarkThemeOn } = useContext(ThemeContext)

  return (
    <div className={inputStyle.inputContainer}>
      {props.label && <span className={inputStyle.label}>{props.label}</span>}
      <input
        {...props}
        className={`${inputStyle.input} ${
          isDarkThemeOn ? inputStyle.dark : ""
        }`}
      />
    </div>
  )
}

export default Input
